<template>
  <container ref="container" @error="init">
    <!-- 顶部 -->
    <publicHeader></publicHeader>

    <!-- 横幅 -->
    <publicBanner></publicBanner>

    <!-- 面包屑导航 -->
    <breadcrumb :router="router"></breadcrumb>

    <!-- 课程-详情 -->
    <div class="courseDetail" v-html="detail.content"></div>

    <!-- 底部 -->
    <publicFooter></publicFooter>

    <!-- 联系 -->
    <contact></contact>
  </container>
</template>

<script>
import publicHeader from "@/components/publicHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
import publicBanner from "@/components/publicBanner.vue";
import contact from "@/components/contact.vue";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "courseDetail",
  components: {
    publicHeader,
    publicFooter,
    publicBanner,
    breadcrumb,
    contact,
  },
  computed: {
    router() {
      return ["首页", "课程详情", this.detail.title];
    },
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/courseDtail", {
          params: {
            id: this.$route.query.id,
          },
        });

        // 设置数据
        this.detail = result.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 课程详情
  .courseDetail {
    width: 1200px;
    margin: 40px auto 70px;
    padding-top: 50px;
    padding-bottom: 30px;
    padding: 20px;
    border: 1px solid #e6e6e6;
    color: #a5a5a5;
    background-color: #f8f8f8;
    font-size: 16px;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 课程详情
  .courseDetail {
    width: rpx(750);
    box-sizing: border-box;
    padding: rpx(40);
    font-size: rpx(32);
    margin-bottom: rpx(40);

    word-break: break-all;
    white-space: pre-wrap;
  }
}
</style>
